export function uriToUrl(uri: string): string {
    // Check if it's already a full URL
    if (uri.startsWith('http://') || uri.startsWith('https://')) {
      return uri;
    }
    
    // Remove leading slashes and clean up double slashes
    const cleanUri = uri.replace(/^\/+/, '').replace(/\/+/g, '/');
    
    // Construct the S3 URL
    return `https://tick3ttree-events-tickets.s3.amazonaws.com/${cleanUri}`;
  }


  export function transformToCloudFrontUrl(s3Url: string) {
    // console.log("S3 URL:", s3Url);
    try {
      const url = new URL(s3Url);
      const cloudFrontDomain = 'https://d2hpk16e9jfjkn.cloudfront.net';
      const pathname = url.pathname.startsWith('/') ? url.pathname.slice(1) : url.pathname;
      const splitPathname = splitFileNameAndExtension(pathname);
      if (!splitPathname) {
        return null;
      }
      return [`${cloudFrontDomain}/${splitPathname[0]}`, splitPathname[1]];
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }

  export  function splitFileNameAndExtension(url:string) {
    if (typeof url !== 'string') {
      console.error('Invalid input: URL must be a string');
      return null;
    }
  
    // Remove query parameters if present
    const urlWithoutQuery = url.split('?')[0];
  
    // Match the last occurrence of a dot followed by one or more characters
    // that are not a dot or a forward slash
    const match = urlWithoutQuery.match(/\.([^/.]+)$/);
  
    if (match) {
      const extension = match[0];  // Includes the dot
      const nameWithoutExtension = urlWithoutQuery.slice(0, -extension.length);
      return [nameWithoutExtension, extension];
    } else {
      // If no extension is found, return the whole string as the name and an empty string as the extension
      return [urlWithoutQuery, ''];
    }
  }

  export function getSecondToLastSegment(url:string) {
    if (typeof url !== 'string') {
      console.error('Invalid input: URL must be a string');
      return null;
    }
    
  
    // Remove query parameters and trailing slash if present
    const cleanUrl = url.split('?')[0].replace(/\/$/, '');
  
    // Split the URL into segments
    const segments = cleanUrl.split('/');
  
    // Return the second-to-last segment if it exists, otherwise null
    return segments.length > 1 ? segments[segments.length - 1] : null;
  }