import { create } from "zustand";
import { getAuthHeadersAndBody } from "@/helpers/web3auth/verifyToken";
import { transformToCloudFrontUrl, splitFileNameAndExtension, getSecondToLastSegment } from "@/utils/urls";
import {toast } from "react-toastify";
type AssetsStore = {
  tickets: TTicket[];
  ticket: TTicket;
  collectible: TCollectible;
  eventAssets: TEventAssets[];
  collectiblesPage: number;
  hasMoreCollectibles: boolean;
  isFetchingAssets: boolean;
  cnfts: TAsset[];
  fetchAssets: (web3Auth: any, getAssets: any) => Promise<void>;
  clearAssets: () => void;
  optimisticallyAddCollectible: (collectible: any, collectibleInfo: any) => void;
  optimisticallyAddTicket: (ticket: any, ticketInfo: any) => void;

  removeCnft: (collectibleId: string) => void;
  removeTicket: (ticketId: string) => void;
  removeCollectible: (collectibleId: string) => void;
};







export const useAssetsStore = create<AssetsStore>((set, get) => ({
  ticket: {
    id: "",
    name: "",
    description: "",
    price: 0,
    quantity: 0,
    event_id: "",
    created_at: "",
    updated_at: "",
    mediaUploaded: false,
    metadataUpdated: false,
    nftData: undefined,
  },
  tickets: [],
  cnfts: [],
  collectible: {
    id: "",
    order: 0,
    event_id: "",
    organization_id: "",
    collectibleName: "",
    collectibleDescription: "",
    collectibleSymbol: "",
    bundleData: {
      id: "",
      name: "",
      symbol: "",
      status: "",
    },
    tierData: {
      id: "",
      name: "",
      description: "",
      rarity: 0,
    },
    image_url: "",
    isMinted: false,
    nftData: undefined,
  },
  eventAssets: [],
  collectiblesPage: 1,
  hasMoreCollectibles: true,
  isFetchingAssets: false,

  fetchAssets: async (web3Auth: any, getAssets:any) => {
    const { collectiblesPage, eventAssets } = get();
    if (!get().hasMoreCollectibles) return;
  
    set({ isFetchingAssets: true });
  
    try {
      // console.log("Total body:", totalBody);
  
      const res = await fetch(`/api/v1/users/assets?page=${collectiblesPage}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
    
      if (!res.ok) {
        throw new Error('Failed to fetch user assets');
      }
  
      const data = await res.json();
      console.log("Fetched collectibles:", data.collectibles);
      console.log("Fetched tickets:", data.tickets);
      // toast.info(`Fetched ${data.collectibles.length} collectibles and ${data.tickets.length} tickets`);
      
      let assets: TAsset[] = [];
      if (web3Auth) {
        assets = await getAssets();
        console.log("Fetched blockchain assets:", assets);
        // toast.info(`Fetched ${assets.length} blockchain assets`);
      }
      // Instead, directly call processCollectibles with the raw data
      // console.log("Calling processCollectibles with:", data.collectibles, data.tickets, assets);
      const newEventAssets = await processCollectibles(data.collectibles, data.tickets, assets);
      // console.log("Processed event assets:", newEventAssets);
  
      set((state) => {
        const mergedEventAssets = mergeEventAssets(state.eventAssets, newEventAssets || []);
        return {
          eventAssets: mergedEventAssets,
          collectiblesPage: state.collectiblesPage + 1,
          hasMoreCollectibles: data.collectibles.length === 20,
          isFetchingAssets: false,
          cnfts: assets,
        };
      });
    } catch (error) {
      console.error("Failed to fetch collectibles:", error);
      set({ isFetchingAssets: false });
    }
  },

  // },
  clearAssets: () => {
    set({ eventAssets: [], tickets: [], collectiblesPage: 1, hasMoreCollectibles: true, isFetchingAssets: false, cnfts: [] });
  },
  optimisticallyAddCollectible: (collectible: any, collectibleInfo: any) => {
    const { eventAssets } = get();
    const eventAssetsCopy = [...eventAssets];
    let eventIndex = eventAssetsCopy.findIndex(
      eventAsset => eventAsset.event.id === collectible.event_id)
    if (eventIndex === -1) {
      eventAssetsCopy.push({
        event: {
          id: collectibleInfo.event.id,
          name: collectibleInfo.event.name,
          start_datetime_local: collectibleInfo.event.start_datetime_local,
          end_datetime_local: collectibleInfo.event.end_datetime_local,
          address: collectibleInfo.address,
          organizationId: collectibleInfo.event.organization_id,
          banner_url: collectibleInfo.event.banner_url,
        },
        collectibles: [],
        tickets: [],
      });
      eventIndex = eventAssetsCopy.length - 1;
    } 
    eventAssetsCopy[eventIndex].collectibles.push({
      id: collectible.id,
      event_id: collectibleInfo.event.id,
      organization_id: collectibleInfo.event.organization_id,
      order: collectibleInfo.order,
      collectibleName: collectibleInfo.name,
      collectibleDescription: collectibleInfo.description,
      collectibleSymbol: collectibleInfo.symbol,
      bundleData: {
        id: collectibleInfo.bundle.id,
        name: collectibleInfo.bundle.name,
        symbol: collectibleInfo.bundle.symbol,
        status: collectibleInfo.bundle.status,
      },
      tierData: {
        id: collectibleInfo.tier.id,
        name: collectibleInfo.tier.name,
        description: collectibleInfo.tier.description,
        rarity: collectibleInfo.tier.rarity,
      },
      image_url: `https://d2hpk16e9jfjkn.cloudfront.net/organization-images/${collectibleInfo.event.organization_id}`,
      isMinted: collectibleInfo.collectibleStatus === "minted",
    });

    set({ eventAssets: eventAssetsCopy });
  },
  optimisticallyAddTicket: (ticket: any, ticketInfo: any) => {
    const { eventAssets } = get();
    const eventAssetsCopy = [...eventAssets];
    let eventIndex = eventAssetsCopy.findIndex(
      eventAsset => eventAsset.event.id === ticket.event_id
    );
    if (eventIndex === -1) {
      eventAssetsCopy.push({
        event: {
          id: ticketInfo.event.id,
          name: ticketInfo.event.name,
          start_datetime_local: ticketInfo.event.start_datetime_local,
          end_datetime_local: ticketInfo.event.end_datetime_local,
          address: ticketInfo.address,
          organizationId: ticketInfo.event.organization_id,
          banner_url: ticketInfo.event.banner_url,
        },
        collectibles: [],
        tickets: [],
      });
      eventIndex = eventAssetsCopy.length - 1;
    }

    eventAssetsCopy[eventIndex].tickets.push({
      id: ticket.id,
      name: ticketInfo.name,
      description: ticketInfo.description,
      price: ticketInfo.price,
      quantity: ticketInfo.quantity,
      event_id: ticketInfo.event.id,
      mediaUploaded: ticketInfo.mediaUploaded,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      metadataUpdated: ticketInfo.metadataUpdated,
      nftData: undefined,
    });



    set({ eventAssets: eventAssetsCopy });
  },

  removeCnft: (collectibleId: string) => {
    const { eventAssets } = get();
    const eventAssetsCopy = [...eventAssets];
    const eventIndex = eventAssetsCopy.findIndex(
      eventAsset => eventAsset.event.id === collectibleId
    );
    if (eventIndex === -1) return;
    eventAssetsCopy[eventIndex].collectibles = eventAssetsCopy[eventIndex].collectibles.filter(collectible => collectible.id !== collectibleId);
    set({ eventAssets: eventAssetsCopy });
  },
  removeTicket: (ticketId: string) => {
    const { eventAssets } = get();
    const eventAssetsCopy = [...eventAssets];
    const eventIndex = eventAssetsCopy.findIndex(
      eventAsset => eventAsset.event.id === ticketId
    );
    if (eventIndex === -1) return;
    eventAssetsCopy[eventIndex].tickets = eventAssetsCopy[eventIndex].tickets.filter(ticket => ticket.id !== ticketId);
    set({ eventAssets: eventAssetsCopy });
  },
  removeCollectible: (collectibleId: string) => {
    const { eventAssets } = get();
    const eventAssetsCopy = [...eventAssets];
    const eventIndex = eventAssetsCopy.findIndex(
      eventAsset => eventAsset.event.id === collectibleId
    );
    if (eventIndex === -1) return;
    eventAssetsCopy[eventIndex].collectibles = eventAssetsCopy[eventIndex].collectibles.filter(collectible => collectible.id !== collectibleId);
    set({ eventAssets: eventAssetsCopy });
  },

}));

async function processCollectibles(collectibles: any[], tickets: any[], assets: TAsset[]): Promise<TEventAssets[] | undefined> {
  const eventMap = new Map<string, TEventAssets>();
  // console.log("Starting processCollectibles");
  // console.log("Collectibles:", collectibles);
  // console.log("Tickets:", tickets);
  // console.log("Assets:", assets);

  if (collectibles.length === 0) {
    console.log("No collectibles to process");
  } else {
    // Process collectibles
    for (const collectible of collectibles) {
      // console.log("Processing collectible:", collectible);
      if (!eventMap.has(collectible.event.id)) {
        eventMap.set(collectible.event.id, {
          event: {
            id: collectible.event.id,
            name: collectible.event.name,
            start_datetime_local: collectible.event.start_datetime_local,
            end_datetime_local: collectible.event.end_datetime_local,
            address: collectible.address,
            organizationId: collectible.event.organization_id,
            banner_url: collectible.event.banner_url,
          },
          collectibles: [],
          tickets: [],
        });
      }
      const eventCollectibles = eventMap.get(collectible.event.id);
      if (eventCollectibles) {
        if (collectible.collectibleStatus === "minted") {
          // toast.info("Collectible is minted", {
          //   position: "bottom-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          // });
          // toast.info(`Collectible is minted ${JSON.stringify(assets.map(asset => asset.json_uri))}`);
          const asset_helius = assets.find(asset => getSecondToLastSegment(asset.json_uri.slice(0,-5)) === collectible.id);
          if (!asset_helius) {
            // toast.error(`Asset not found for collectible ${collectible.id}`);
            // console.error(`Asset not found for collectible ${collectible.id}`);
            eventCollectibles.collectibles.push({
              id: collectible.id,
              event_id: collectible.event.id,
              organization_id: collectible.event.organization_id,
              collectibleName: collectible.collectibleName,
              collectibleDescription: collectible.collectibleDescription,
              collectibleSymbol: collectible.collectibleSymbol,
              order: collectible.order,
              bundleData: {
                id: collectible.bundle.id,
                name: collectible.bundle.name,
                symbol: collectible.bundle.symbol,
                status: collectible.bundle.status,
              },
              tierData: {
                id: collectible.tier?.id || "",
                name: collectible.tier?.name || "",
                description: collectible.tier?.description || "",
                rarity: collectible.tier?.rarity || 0,
              },
              image_url: `https://d2hpk16e9jfjkn.cloudfront.net/organization-images/${collectible.event.organization_id}`,
              isMinted: false,
            });
          } else {
          try {
            // toast.info(`Fetching metadata for collectible ${collectible.id} ${asset_helius.json_uri}`);
            const response = await fetch(asset_helius.json_uri.slice(0,-5));
            // toast.info(`Response: ${JSON.stringify(response)}`);
            const metadata = await response.json();
            // toast.info(`Metadata: ${JSON.stringify(metadata)}`);
            // console.log(metadata);

            const animationSplit = transformToCloudFrontUrl(metadata.animation_url);
            // toast.info(`Animation split: ${animationSplit}`);
            if (!animationSplit) continue;
            

            eventCollectibles.collectibles.push({
              id: collectible.id,
              event_id: collectible.event.id,
              organization_id: collectible.event.organization_id,
              collectibleName: collectible.collectibleName,
              collectibleDescription: collectible.collectibleDescription,
              collectibleSymbol: collectible.collectibleSymbol,
              order: collectible.order,
              bundleData: {
                id: collectible.bundle.id,
                name: collectible.bundle.name,
                symbol: collectible.bundle.symbol,
                status: collectible.bundle.status,
              },
              tierData: {
                id: collectible.tier.id,
                name: collectible.tier.name,
                description: collectible.tier.description,
                rarity: collectible.tier.rarity,
              },
              image_url: `https://d2hpk16e9jfjkn.cloudfront.net/organization-images/${collectible.event.organization_id}`,
              isMinted: collectible.collectibleStatus === "minted",
              nftData: {
                id: asset_helius.id,
                json_uri: asset_helius.json_uri,
                name: asset_helius.name, 
                description: metadata.description,
                image: metadata.image,
                animation_url: animationSplit[0],
                attributes: metadata.attributes,
              },
          });} catch (error) {
            // toast.error(`Failed to fetch metadata for collectible ${collectible.id}: ${error}, ${asset_helius.json_uri}`);
            // console.error("Failed to fetch metadata for collectible:", error);
            eventCollectibles.collectibles.push({
              id: collectible.id,
              event_id: collectible.event.id,
              organization_id: collectible.event.organization_id,
              collectibleName: collectible.collectibleName,
              collectibleDescription: collectible.collectibleDescription,
              collectibleSymbol: collectible.collectibleSymbol,
              order: collectible.order || null,
              bundleData: {
                id: collectible.bundle.id,
                name: collectible.bundle.name,
                symbol: collectible.bundle.symbol,
                status: collectible.bundle.status,
              },
              tierData: {
                id: collectible.tier.id,
                name: collectible.tier.name,
                description: collectible.tier.description,
                rarity: collectible.tier.rarity,
              },
              image_url: `https://d2hpk16e9jfjkn.cloudfront.net/organization-images/${collectible.event.organization_id}`,
              isMinted: collectible.collectibleStatus === "minted",
            });
          }
        }
      } else {
        // toast.info(`Collectible ${collectible.id} already exists`);
        eventCollectibles.collectibles.push({
          id: collectible.id,
          event_id: collectible.event.id,
          organization_id: collectible.event.organization_id,
          collectibleName: collectible.collectibleName,
          collectibleDescription: collectible.collectibleDescription,
          collectibleSymbol: collectible.collectibleSymbol,
          order: collectible.order || null,
          bundleData: {
            id: collectible.bundle.id,
            name: collectible.bundle.name,
            symbol: collectible.bundle.symbol,
            status: collectible.bundle.status,
          },
          tierData: undefined,
          image_url: `https://d2hpk16e9jfjkn.cloudfront.net/organization-images/${collectible.event.organization_id}`,
          isMinted: collectible.collectibleStatus === "minted",
        });
      }
    }
  }}

  // console.log("Starting ticket processing");
  if (tickets.length === 0) {
    console.log("No tickets to process");
  } else {
    // Process tickets
    for (const ticket of tickets) {
      // console.log("Processing ticket:", ticket);
      if (!eventMap.has(ticket.event_id)) {
        // If the event doesn't exist in the map, create it
        eventMap.set(ticket.event_id, {
          event: {
            id: ticket.event.id,
            name: ticket.event.name,
            start_datetime_local: ticket.event.start_datetime_local,
            end_datetime_local: ticket.event.end_datetime_local,
            address: ticket.address,
            organizationId: ticket.event.organization_id,
            banner_url: ticket.event.banner_url,
          },
          collectibles: [],
          tickets: [],
        });
      }
      
      const eventCollectibles = eventMap.get(ticket.event_id);
      if (eventCollectibles) {
        // toast.info(`Processing ticket ${ticket.id}`);
        const ticketData: TTicket = {
          id: ticket.id,
          name: ticket.name,
          description: ticket.description,
          price: ticket.price,
          quantity: ticket.quantity,
          event_id: ticket.event_id,
          mediaUploaded: ticket.mediaUploaded,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          metadataUpdated: ticket.metadataUpdated,
          nftData: undefined,
        };
        console.log(ticketData);
        const asset_helius = assets.find(asset => getSecondToLastSegment(asset.json_uri.slice(0, -5)) === ticket.id);
        console.log(asset_helius);
        // toast.info(`Asset Helius: ${asset_helius}`);
        if (asset_helius) {
          // toast.info(`Asset Helius: ${JSON.stringify(asset_helius)}`);
          try {
            const response = await fetch(asset_helius.json_uri.slice(0, -5));
            const metadata = await response.json();
            console.log(metadata);
            // toast.info(`Metadata: ${JSON.stringify(metadata)}`);
            if (ticket.metadataUpdated) {
              const animationSplit = transformToCloudFrontUrl(metadata.animation_url);
              // toast.info(`Animation split: ${animationSplit}`);
              ticketData.nftData = {
                id: asset_helius.id,
                json_uri: asset_helius.json_uri,
                name: asset_helius.name,
                description: ticket.description,
                image: metadata.image,
                animation_url: animationSplit?.[0] || "",
                attributes: metadata.attributes || [],
              };
            } else {
              // toast.info(`Metadata not updated for ticket ${ticket.id}`);
              ticketData.nftData = {
                id: asset_helius.id,
                json_uri: asset_helius.json_uri,
                name: asset_helius.name,
                description: ticket.description,
                image: metadata.image,
                animation_url: "",
                attributes: metadata.attributes || [],
              };
            }
          } catch (error) {
            // toast.error(`Failed to fetch metadata for ticket ${ticket.id}: ${error}, ${asset_helius.json_uri}`);
            console.error("Failed to fetch metadata for ticket:", error);
          }
        }

        eventCollectibles.tickets.push(ticketData);
      }
    }
  }

  // console.log("Finished processCollectibles");
  // console.log("Final eventMap:", Array.from(eventMap.values()));
  return Array.from(eventMap.values());
}




const mergeEventAssets = (existing: TEventAssets[], newItems: TEventAssets[]): TEventAssets[] => {
  const merged = [...existing];
  newItems.forEach(newItem => {
    const existingIndex = merged.findIndex(item => item.event.id === newItem.event.id);
    if (existingIndex !== -1) {
      // Event exists, merge collectibles
      merged[existingIndex].collectibles = [
        ...merged[existingIndex].collectibles,
        ...newItem.collectibles.filter(newCollectible => 
          !merged[existingIndex].collectibles.some(existingCollectible => 
            existingCollectible.id === newCollectible.id
          )
        )
      ];
      merged[existingIndex].tickets = [
        ...merged[existingIndex].tickets,
        ...newItem.tickets.filter(newTicket => 
          !merged[existingIndex].tickets.some(existingTicket => 
            existingTicket.id === newTicket.id
          )
        )
      ];
    } else {
      // New event, add it to the array
      merged.push(newItem);
    }
  });
  return merged;
};