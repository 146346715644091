import { CartProduct } from "@/types/product";
import { totalmem } from "os";
import { create } from "zustand";


// Define the interface of the Cart state
interface State {
  cart: CartItem[];
  totalItems: number;
  totalPrice: number;
  cartOpen: boolean;
}

// Define the interface of the actions that can be performed in the Cart
interface Actions {
  addToCart: (Item: CartItem) => void;
  removeFromCart: (Item: CartItem) => void;
  toggleCart: () => void;
  incrementQuantity: (id: string) => void;
  decrementQuantity: (id: string) => void;
  clearCart: () => void;
  
}

const INITIAL_STATE = {
  cart: [],
  totalItems: 0,
  totalPrice: 0,
  cartOpen: false,
};

// Create the store with Zustand, combining the status interface and actions
export const useCartStore = create<State & Actions>((set, get) => ({
  cart: INITIAL_STATE.cart,
  totalItems: INITIAL_STATE.totalItems,
  totalPrice: INITIAL_STATE.totalPrice,
  cartOpen: INITIAL_STATE.cartOpen,

  addToCart: (product: CartItem) => {
    // console.log(product);
    const cart = get().cart;
    const cartItem = cart.find((item) => item.id === product.id);

    // If the item already exists in the Cart, increase its quantity
    if (cartItem) {
      if (cartItem.type === "collectible") {
        set((state) => (
        {
          cart: cart, 
          totalItems: state.totalItems,
          totalPrice: state.totalPrice,
        }))
      } else {

      const updatedCart = cart.map((item) =>
        item.id === product.id
          ? { ...item, quantity: (item.quantity as number) + 1 }
          : item
      );
      set((state) => ({
        cart: updatedCart,
        totalItems: state.totalItems + 1,
        totalPrice: state.totalPrice + product.price,
      }));
    }} else {
      const updatedCart = [...cart, { ...product, quantity: 1 }];

      set((state) => ({
        cart: updatedCart,
        totalItems: state.totalItems + 1,
        totalPrice: state.totalPrice + product.price,
      }));

      // console.log("Total Items", get().totalItems);
      // console.log("Total Price", get().totalPrice);
    }
  },

  removeFromCart: (product: CartItem) => {
    set((state) => ({
      cart: state.cart.filter((item) => item.id !== product.id),
      totalItems: state.totalItems - 1,
      totalPrice: state.totalPrice - product.price,
    }));
  },

  incrementQuantity: (id: string) => {
    const cart = get().cart;
    const cartItem = cart.find((item) => item.id === id);
    if (cartItem?.type === "collectible") {
      set((state) => (
        {
          cart: cart, 
          totalItems: state.totalItems,
          totalPrice: state.totalPrice,
        }))
    } else {

    set((state) => ({
      cart: cart.map((item) =>
        item.id === id
          ? { ...item, quantity: (item.quantity as number) + 1 }
          : item
      ),
      totalItems: state.totalItems + 1,
      totalPrice: state.totalPrice + (cartItem?.price as number),
    }));
  }},
  decrementQuantity: (id: string) => {
    const cart = get().cart;
    const cartItem = cart.find((item) => item.id === id);

    if (cartItem?.quantity === 1) {
      set((state) => ({
        cart: state.cart.filter((item) => item.id !== id),
        totalItems: state.totalItems - 1,
        totalPrice: state.totalPrice - (cartItem?.price as number),
      }));
    } else {
      set((state) => ({
        cart: cart.map((item) =>
          item.id === id
            ? { ...item, quantity: (item.quantity as number) - 1 }
            : item
        ),
        totalItems: state.totalItems - 1,
        totalPrice: state.totalPrice - (cartItem?.price as number),
      }));
    }
  },

  toggleCart: () => {
    set((state) => ({
      cartOpen: !state.cartOpen,
    }));
  },
  clearCart: () => set({ cart: [], totalItems: 0, totalPrice: 0 }),
}));
