import { useCartStore } from "@/store/useCartStore";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

const CartItemIncreaseDecrease = ({
  value,
  increase,
  decrease,
}: {
  value: number;
  increase: () => void;
  decrease: () => void;
}) => {
  return (
    <div className="flex gap-2 items-center border border-neutral-700 rounded-lg bg-neutral-800 h-8 w-auto ">
      <div
        onClick={(e) => {
          e.stopPropagation();
          decrease();
        }}
        className="cursor-pointer pl-2"
      >
        <MinusIcon className="w-3 h-3 cursor-pointer hover:text-white" />
      </div>
      <div className="border-l border-r border-neutral-700 w-12 text-center select-none">
        {value || 0}
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          increase();
        }}
        className="cursor-pointer text-center pr-2"
      >
        <PlusIcon className="w-3 h-3 hover:text-white" />
      </div>
    </div>
  );
};

export default CartItemIncreaseDecrease;
